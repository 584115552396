<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <!-- :editedIndex="editedIndex" -->
            <reference-tool-bar
              :title="options.title.main"
              :createItemTitle="options.title.create"
              :formTitle="formTitle"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
              @export-table="exportTableToXLSX()"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-autocomplete
                    v-model="organizationSelect"
                    :hint="
                      `Идентификатор организации - [${
                        organizationSelect ? organizationSelect.id : ''
                      }]`
                    "
                    :items="organizationList"
                    item-text="nameRu"
                    item-value="id"
                    label="Организация"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="roleSelect"
                    :hint="`Идентификатор роли - [${roleSelect.id}]`"
                    :items="roleList"
                    item-text="name"
                    item-value="id"
                    label="Роль"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.iin"
                    label="ИИН"
                    :hint="`Введите 12 цифр`"
                    :rules="[rules.required, rules.iin]"
                    autofocus
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.userName"
                    label="Логин пользователя"
                    :hint="`Допускаются буквы латиницы, цифры и символ ’.’`"
                    :rules="[rules.required, rules.min, rules.login]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.firstNameRu"
                    label="Имя пользователя"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.alfabet]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.lastNameRu"
                    label="Фамилия пользователя"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.alfabet]"
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.firstNameKz"
                    label="Имя пользователя Kz"
                    :hint="`Допускаются только буквы Kz`"
                    :rules="[rules.alfabet]"
                  ></v-text-field>
                </v-flex> -->
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.lastNameKz"
                    label="Фамилия пользователя Kz"
                    :hint="`Допускаются только буквы Kz`"
                    :rules="[rules.alfabet]"
                  ></v-text-field>
                </v-flex> -->
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.firstNameEn"
                    label="Имя пользователя En"
                    :hint="`Допускаются только буквы En`"
                    :rules="[rules.alfabetEn]"
                  ></v-text-field>
                </v-flex> -->
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.lastNameEn"
                    label="Фамилия пользователя En"
                    :hint="`Допускаются только буквы En`"
                    :rules="[rules.alfabetEn]"
                  ></v-text-field>
                </v-flex> -->

                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.email"
                    label="Электронная почта"
                    :hint="`Введите почту в формате ‘address@isun.kz’`"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.phoneNumber"
                    label="Телефон"
                    :hint="`Введите номер в формате 7778885566`"
                    :rules="[rules.required, rules.phone]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-select
                    v-model="statusSelect"
                    :hint="`Идентификатор статуса - [${statusSelect.id}]`"
                    :items="statusList"
                    item-text="nameRu"
                    item-value="id"
                    label="Статус"
                    persistent-hint
                    return-object
                    single-line
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <v-text-field
                    md6
                    v-model="options.editedItem.password"
                    label="Пароль"
                    name="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.password]"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                  <!-- <v-text-field
                    md6
                    v-model="options.editedItem.confirmpassword"
                    label="Подтверждение пароля"
                    name="confirmpassword"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="[rules.required, rules.password]"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  ></v-text-field> -->
                </v-flex>
              </template>
              
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              ref="reportTable"
              :headers="options.headers"
              :items="options.data"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get, sync } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";
import { randomPasswordGenerator, json2xlsx } from "@/util/helpers";

export default Vue.extend({
  name: "User.vue",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue")
  },
  computed: {
    options: get("reference/optionsUser"),
    organizationList: get("reference/getOrganizations"),
    statusList: get("reference/getStatus"),
    roleList: get("reference/getRoles"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    }
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1,
      showPassword: true,
      // showConfirmPassword: true,
      organizationSelect: 0,
      statusSelect: 0,
      roleSelect: 0
    };
  },
  methods: {
    ...mapActions({
      fetchReference: "reference/fetchUser",
      fetchOrganizations: "reference/fetchOrganizations",
      fetchStatus: "reference/fetchStatus",
      fetchRole: "reference/fetchRole",
      setUserRoles: "reference/setUserRoles",
      excludeManagementRoles: "reference/excludeManagementRoles",
      excludeUsersWithManagementRoles:
        "reference/excludeUsersWithManagementRoles"
    }),
    exportTableToXLSX() {
      var currentTab = this.$refs.reportTable;
      this.options.data.forEach(item => {
        this.options.dataForXLSX.push({
          "organization.nameRu": item.organization.nameRu,
          "role.name": item.role.name,
          "person.firstNameRu": item.person.firstNameRu,
          "person.lastNameRu": item.person.lastNameRu,
          "userName": item.userName,
          "iin": item.iin,
          "email": item.email,
          "phoneNumber": item.phoneNumber,
          "status.nameRu": item.status.nameRu,
        });
      });
      this.options.data.length > 0
        ? json2xlsx({
            data: this.options.dataForXLSX,
            header: [this.options.headersForXLSX.map(header => header.text)],
            sheetName: "Отчет",
            fileName:
              "ИСУНГ - " + "Пользователи" + ".xlsx"
          })
        : console.log(this.errorMessage);
    },
    initialize() {
      const currentLoggedUserRole = JSON.parse(
        localStorage.getItem("user")
      ).role.toLowerCase();

      this.fetchStatus().then(result => {
        this.statusSelect = this.statusList.find(
          status => status.name == "Active"
        );
      });
      this.fetchOrganizations().then(result => {
        this.organizationSelect = this.organizationList[0];
      });
      this.fetchRole()
        .then(result => {
          this.roleSelect = this.roleList[0];
        })
        .then(result => this.fetchReference())
        .then(result => this.setUserRoles())
        .then(result => {
          if (currentLoggedUserRole == "manager") {
            this.excludeUsersWithManagementRoles();
          }
        })
        .then(result => {
          if (currentLoggedUserRole == "manager") {
            this.excludeManagementRoles();
          }
        });

      const randomPassword = randomPasswordGenerator();
      this.options.defaultItem.password = randomPassword;
      this.options.defaultItem.confirmpassword = randomPassword;
      this.options.editedItem = Object.assign({}, this.options.defaultItem);
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      const randomPassword = randomPasswordGenerator();
      setTimeout(() => {
        this.options.defaultItem.password = randomPassword;
        this.options.defaultItem.confirmpassword = randomPassword;
        this.options.editedItem = Object.assign({}, this.options.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const model = {
        id: this.options.editedItem.id,
        iin: this.options.editedItem.iin,
        name: this.options.editedItem.userName,
        firstNameRu: this.options.editedItem.firstNameRu,
        lastNameRu: this.options.editedItem.lastNameRu,
        firstNameKz: this.options.editedItem.firstNameKz,
        lastNameKz: this.options.editedItem.lastNameKz,
        firstNameEn: this.options.editedItem.firstNameEn,
        lastNameEn: this.options.editedItem.lastNameEn,
        email: this.options.editedItem.email,
        phoneNumber: this.options.editedItem.phoneNumber,
        organizationId: this.organizationSelect.id,
        roleName: this.roleSelect.name,
        password: this.options.editedItem.password,
        statusId: this.statusSelect.id
      };
      if (this.editedIndex > -1) {
        this.$http.put(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        model.id = "";
        this.$http.post(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.options.data.indexOf(item);
      this.options.editedItem = Object.assign({}, item);

      this.options.editedItem.firstNameRu = this.options.editedItem.person.firstNameRu;
      this.options.editedItem.lastNameRu = this.options.editedItem.person.lastNameRu;
      this.options.editedItem.firstNameKz = this.options.editedItem.person.firstNameKz;
      this.options.editedItem.lastNameKz = this.options.editedItem.person.lastNameKz;
      this.options.editedItem.firstNameEn = this.options.editedItem.person.firstNameEn;
      this.options.editedItem.lastNameEn = this.options.editedItem.person.lastNameEn;
      this.organizationSelect = {
        id: item.organization.id,
        name: item.organization.NameRu
      };

      this.statusSelect = { id: item.status.id, name: item.status.NameRu };

      // Установка текущей роли пользователя как выбранной
      this.roleSelect = this.roleList.find(
        role => role.id == item.aspNetUserRoles[0].roleId
      );

      this.open();
    },

    removeItem(item) {
      if (confirm("Вы уверены что хотите удалить учетную запись?")) {
        const model = Object.assign({}, item);
        this.$http
          .delete(this.options.path.base, { data: model })
          .then(response => {
            if (response.status === "Ok") this.initialize();
          });
      }
    }
  },
  created() {
    this.initialize();
  }
});
</script>
